<template>
    <div id="doctor-clinics">
        <div class="clinic" v-for="(clinic, index) in doctor.clinicList" :key="index">
            <div class="row">
                <img :src="clinic.logo"/>
                <div class="col">
                    <h2 class="clinic-name"><router-link :to="'/hospital/detail/'+clinic.clinicid" v-text="clinic.clinicname"></router-link></h2>
                    <p class="clinic-area" :title="`地區：${clinic.arealevel1name}-${clinic.arealevel2name}`" v-text="`${clinic.arealevel1name}-${clinic.arealevel2name}`"></p>
                    <p class="clinic-tel" :title="`電話：${clinic.contacttel || '--'}${clinic.contacttelother ? `（${clinic.contacttelother}）` : ''}`" v-text="`${clinic.contacttel}${clinic.contacttelother ? `（${clinic.contacttelother}）` : ''}`"></p>
                    <p class="clinic-email" :title="`電郵：${clinic.email || '--'}`" v-text="`${clinic.email || '--'}`"></p>
                    <p class="clinic-address" :title="`詳細地址：${clinic.address}`" v-text="clinic.address"></p>
                </div>
            </div>
            <router-link :to="'/hospital/detail/'+clinic.clinicid" class="btn-booking">預約</router-link>
        </div>
    </div>
</template>

<script>
	export default {
        props: {
            doctor: undefined,
        },
        data(){
            return {
            }
        },
        mounted: function(){
        }
    }
</script>

<style lang="scss" scoped>
    .btn-booking {
        width: 96px;
        height: 48px;
        line-height: 48px;
        background-color: #FFE3D5;
        border-radius: 4px;
        text-align: center;
        font-size: 26px;
        color: #FF8000;
        font-weight: bold;
        position: absolute;
        top: 30px;
        right: 34px;
    }

    .btn-booking:active {
        opacity: 0.7;
    }

    #doctor-clinics {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }

    #doctor-clinics > .clinic {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 1px 2px 8px 0px #E8EAF3;
        display: flex;
        flex-direction: column;
        padding: 24px 34px 32px 41px;
        border-bottom: 1px solid #ebebeb;
        position: relative;
    }

    #doctor-clinics > .clinic .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }

    #doctor-clinics > .clinic .row img {
        width: 80px;
        height: 80px;
        margin-right: 17px;
    }

    #doctor-clinics > .clinic .row img[src=''] {
        position: relative;
    }

    #doctor-clinics > .clinic .row img[src='']::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 80px;
        background-image: url(../../../assets/imgs/pc/img_moren@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #fff;
    }

    #doctor-clinics > .clinic .col {
        display: flex;
        flex-direction: column;
    }

    #doctor-clinics > .clinic:last-child {
        margin-bottom: 20px;
    }

    #doctor-clinics > .clinic p {
        display: inline-block;
    }

    #doctor-clinics > .clinic .clinic-name {
        color: #242020;
        font-size: 34px;
        font-weight: bold;
        line-height: 36px;
    }

    #doctor-clinics > .clinic .clinic-area {
        color: #969696;
        font-size: 24px;
        line-height: 26px;
        font-weight: 400;
        margin-top: 10px;
    }

    #doctor-clinics > .clinic .clinic-tel,
    #doctor-clinics > .clinic .clinic-email,
    #doctor-clinics > .clinic .clinic-address {
        color: #242020;
        font-size: 28px;
        line-height: 30px;
        font-weight: 400;
        padding-left: 35px;
        position: relative;
    }

    #doctor-clinics > .clinic .clinic-tel {
        margin-top: 36px;
    }

    #doctor-clinics > .clinic .clinic-email {
        margin-top: 16px;
    }

    #doctor-clinics > .clinic .clinic-address {
        margin-top: 17px;
    }

    #doctor-clinics > .clinic .clinic-tel::after,
    #doctor-clinics > .clinic .clinic-email::after,
    #doctor-clinics > .clinic .clinic-address::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_iphone@2x.png);
    }

    #doctor-clinics > .clinic .clinic-email::after {
        background-image: url(../../../assets/imgs/pc/img_youjian@2x.png);
        width: 24px;
        height: 24px;
    }

    #doctor-clinics > .clinic .clinic-address::after {
        background-image: url(../../../assets/imgs/pc/img_dib@2x.png);
        width: 24px;
        height: 28px;
    }
</style>
